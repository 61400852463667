import React from "react"
import Image from "@components/common/image"

const OurPortfolioCarouselCard = props => {
  const { data, translateValue } = props
  return (
    <div ref={props.forwardedRef} className="carousel-card" style={{transform: `translateX(${translateValue}px)`}}>
      <div className="carousel-card-body">
        <h5 className="card-number">
          {data.id < 10 ? `0${data.id}` : data.id}
        </h5>
        <div className="line"></div>
        <Image className="card-avatar" src={data.image} roundedCircle fluid />
        <h3 className="card-company-info">{data.companyName}</h3>
        <h1 className="card-person-info">
          {data.name}, {data.region}
        </h1>
        <h5 className="card-description">{data.description}</h5>
        <div className="extra-info">
          <div className="investment-info">
            <p>{data.investmentDate}</p>
            <h5>Investment date</h5>
          </div>
          <div className="space-info">
            <p>{data.space}</p>
            <h5>Space</h5>
          </div>
        </div>
      </div>
      <div className="carousel-card-footer">
        <a
          className="card-link"
          href={data.website}
          target="_blank"
          rel="noreferrer"
        >
          Visit website
        </a>
      </div>
    </div>
  )
}

export default OurPortfolioCarouselCard
