import React from "react"
import { Col, Row } from "react-bootstrap"
import OurPortfolioCarouselCard from "./our-portfolio-carousel-card"
import ArrowIcon from "../../../images/arrow-right.inline.svg"

const privateEquity = [
  {
    id: 1,
    image: "pt-rudy-sayegh.png",
    companyName: "Global Gate Capital",
    name: "Rudy Sayegh",
    region: "Global",
    description:
      "Global Gate is an investment firm that manages multiple asset classes through its group of companies and specializes in real estate, private equity and wealth management.",
    investmentDate: "Jan, 2015",
    space: "Asset Management",
    website: "https://www.globalgatecapital.com/",
  },
  {
    id: 2,
    image: "pt-manhal-nasser.png",
    companyName: "AWJ Investments",
    name: "Manhal Naser",
    region: "UAE",
    description:
      "Founded and headquartered in Dubai, AWJ Investments is a fast-growing Food & Beverage holding group that explores popular Middle Eastern, Mediterranean, and International flavors.",
    investmentDate: "Jul, 2018",
    space: "Food & Beverage",
    website: "https://awj.ae/en/",
  },
  {
    id: 3,
    image: "pt-zaid-shouly.png",
    companyName: "The Captain's Club",
    name: "Zaid Al Shouly",
    region: "UAE",
    description:
      "It is UAE’s Biggest Boat club with a fleet size of over 100 boats spread across numerous prestigious marinas in Abu Dhabi and Dubai, providing its members an alternative solution to boat ownership.",
    investmentDate: "Jul, 2019",
    space: "Sharing Economy",
    website: "https://www.thecaptainsclub.ae/",
  },
]

const ventureCapital = [
  {
    id: 1,
    image: "pt-samarth-agrawal.png",
    companyName: "MaxWholesale",
    name: "Samarth Agrawal",
    region: "India",
    description:
      "It is a B2B e-commerce platform in India for neighborhood grocery stores (Kiranas) to source inventory online. Kirana stores discover price and scheme on various brands and product and order the same on MaxWholesale for re-selling.",
    investmentDate: "Jul, 2019",
    space: "E-commerce",
    website: "https://www.maxwholesale.in/",
  },
  {
    id: 2,
    image: "pt-ahad-bhai.png",
    companyName: "Bongo",
    name: "Ahad Bhai",
    region: "Bangladesh",
    description:
      "Bangladesh's leading digital media company, Bongo is a VOD platform that manages a network of YouTube channels and influencers in 5 countries with over 500 Channels under management and 83+ million subscribers in the network.",
    investmentDate: "Dec, 2019",
    space: "Video Streaming",
    website: "https://bongobd.com/",
  },
  {
    id: 3,
    image: "pt-fairoze-rahman.png",
    companyName: "Loosely Coupled Technologies",
    name: "Fairoze Rahman",
    region: "Malaysia",
    description:
      "Loosely Coupled is a technology startup focused on harnessing the benefits of NLP and AI by using data to help bridge the digital gap for businesses and individuals.",
    investmentDate: "Dec, 2019",
    space: "Artificial Intelligence",
    website: "http://www.looselycoupled.org/",
  },
  {
    id: 4,
    image: "pt-waseem-alim.png",
    companyName: "Chaldal.com",
    name: "Waseem Alim",
    region: "Bangladesh",
    description:
      "One of Bangladesh’s first and most popular e-commerce grocers, pioneering one hour deliveries in Dhaka through the adoption of micro-warehousing model. This has led to solving critical logistical issues faced in densely populated cities.",
    investmentDate: "Dec, 2019",
    space: "E-commerce",
    website: "https://chaldal.com/",
  },
  {
    id: 5,
    image: "pt-abdullah-mutawi.png",
    companyName: "Dubai Angel Investors",
    name: "Abdullah Mutawi",
    region: "Dubai",
    description:
      "A member-led Micro VC comprising of more than 100 members. It invests in tech startups with high growth potential, raising funds for their Seed or Series A rounds.",
    investmentDate: "Feb, 2020",
    space: "Venture Capital",
    website: "https://www.dubaiangelinvestors.me/",
  },
  {
    id: 6,
    image: "pt-oussama-gillah.png",
    companyName: "Razor Capital",
    name: "Oussama Gillah",
    region: "London",
    description:
      "Razor Capital is a venture capital and investment management firm with a focus on frontier markets. The firm invests and provides advisory services to entrepreneurs and early-stage, fast growing companies.",
    investmentDate: "Dec, 2019",
    space: "Venture Capital",
    website: "https://razorcap.co/",
  },
  {
    id: 7,
    image: "pt-tavis-butler.png",
    companyName: "Bsquared",
    name: "Tavis Butler",
    region: "Dubai",
    description:
      "Bsquared is the market leading service provider of “Wifi as a Service” (WaaS), capable to invest, provision and monetize large scale cloud managed Wifi networks for owners and operators of staff accommodation and labor camps.",
    investmentDate: "May, 2019",
    space: "WiFi Technology",
    website: "https://www.bsquaredwifi.com/",
  },
]

const OurPortfolioCarousel = props => {
  const [translateValue, setTranslateValue] = React.useState(0)
  const cardRef = React.useRef()

  React.useEffect(() => {
    setTranslateValue(0)
  }, [props.selectedCategory])

  let dataset = []
  if (props.selectedCategory === "ventureCapital") {
    dataset = ventureCapital
  } else {
    dataset = privateEquity
  }

  function handleSlide(e, action) {
    e.preventDefault()
    const windowWidth = window.screen.availWidth
    const cardWidth = cardRef.current.clientWidth + 4

    let cardCount = 1
    if (windowWidth > 640) {
      cardCount = 2
    }
    if (windowWidth > 990) {
      cardCount = 3
    }

    const maxWidth =
      dataset.length > 3
        ? Math.round(dataset.length / 3) * 3 * cardWidth
        : (dataset.length - 1) * cardWidth
    let newTranslateValue = translateValue

    switch (action) {
      case "next":
        if (newTranslateValue > -maxWidth) {
          newTranslateValue -= cardWidth * cardCount
        }
        break
      case "prev":
        if (newTranslateValue !== 0) {
          newTranslateValue += cardWidth * cardCount
        }
        break
      default:
        break
    }
    setTranslateValue(newTranslateValue)
  }

  return (
    <Row>
      {dataset.length <= 3 ? (
        <></>
      ) : (
        <Col xs={1} className="actions-col d-none d-lg-flex">
          <a
            className={`arrow-icon ${translateValue === 0 ? "d-none" : ""}`}
            href="#slide"
            onClick={e => handleSlide(e, "prev")}
          >
            <ArrowIcon className={`arrow-left`} />
          </a>
          <a
            className={`arrow-icon ${translateValue === 0 ? "" : "d-none"}`}
            href="#slide"
            onClick={e => handleSlide(e, "next")}
          >
            <ArrowIcon className={`arrow-right`} />
          </a>
          <a
            className={`arrow-icon ${translateValue === 0 ? "d-none" : ""}`}
            href="#slide"
            onClick={e => handleSlide(e, "next")}
          >
            <ArrowIcon className={`arrow-right`} />
          </a>
        </Col>
      )}

      <Col
        xs={12}
        lg={dataset.length <= 3 ? 12 : 11}
        className="our-portfolio-cards-container"
      >
        <Row
          className={`flex-nowrap ${
            dataset.length <= 3 ? "justify-content-lg-center" : ""
          }`}
        >
          {dataset.map(data => (
            <React.Fragment key={`${data.name}`}>
              <OurPortfolioCarouselCard
                translateValue={translateValue}
                forwardedRef={cardRef}
                data={data}
              />
            </React.Fragment>
          ))}
        </Row>
      </Col>

      {dataset.length === 1 ? (
        <></>
      ) : (
        <>
          <Col xs={6} className="actions-col d-lg-none">
            <a
              className={`arrow-icon ${translateValue === 0 ? "d-none" : ""}`}
              href="#slide"
              onClick={e => handleSlide(e, "prev")}
            >
              <ArrowIcon className={`arrow-left`} />
            </a>
            <a
              className={`arrow-icon ${translateValue === 0 ? "" : "d-none"}`}
              href="#slide"
              onClick={e => handleSlide(e, "next")}
            >
              <ArrowIcon className={`arrow-right `} />
            </a>
          </Col>
          <Col xs={6} className="actions-col justify-content-end d-lg-none">
            <a
              className={`arrow-icon ${translateValue === 0 ? "d-none" : ""}`}
              href="#slide"
              onClick={e => handleSlide(e, "next")}
            >
              <ArrowIcon className={`arrow-right `} />
            </a>
          </Col>
        </>
      )}
    </Row>
  )
}

export default OurPortfolioCarousel
