import React from "react";

import Layout from "@components/layout"
import SEO from "@components/common/seo"
import OurPortfolioHero from "@components/views/our-portfolio/our-portfolio-hero";
import OurPortfolioContent from "@components/views/our-portfolio/our-portfolio-content";
import ApplyForFunding from "@components/views/home/apply-for-funding";

const OurPortfolioPage = () => {
    return (
        <Layout className="our-portfolio">
            <SEO title="Our Portfolio" />

            <OurPortfolioHero />
            <div id="our-portfolio" className="our-portfolio-content-wrapper">
                <OurPortfolioContent />
            </div>

            <ApplyForFunding />
        </Layout>
    )
}

export default OurPortfolioPage;