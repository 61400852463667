import React from "react"
import {
  Col,
  Container,
  Row,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap"
import OurPortfolioCarousel from "./our-portfolio-carousel"

const portfolioCategories = [
  {
    key: "ventureCapital",
    label: "Venture Capital",
  },
  {
    key: "privateEquity",
    label: "Private Equity",
  },
]

const OurPortfolioContent = () => {
  const [selectedCategory, setSelectedCategory] = React.useState(
    "ventureCapital"
  )

  return (
    <div className="our-portfolio-content-container">
      <Container className="header p-x-30">
        <Row className="justify-content-center">
          <Col
            data-sal="fade"
            data-sal-delay="300"
            data-sal-easing="ease"
            data-sal-duration="1000"
            xs={12}
            md={6}
            className="text-md-center"
          >
            <h4 className="section-title">
              <span>Our</span>
              <span> Portfolio</span>
            </h4>
            <p className="mb-0">
              Our portfolio companies are our venture partners. We work
              hand-in-hand to achieve mutual results and create long-term value.
            </p>
            <ToggleButtonGroup
              type="radio"
              name="portfolioCategory"
              onChange={setSelectedCategory}
              value={selectedCategory}
            >
              {portfolioCategories.map(cat => (
                <ToggleButton key={cat.key} value={cat.key}>
                  {cat.label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Col>
        </Row>
      </Container>

      <Container
        data-sal="fade"
        data-sal-delay="300"
        data-sal-easing="ease"
        data-sal-duration="1000"
        className="sal-animate our-portfolio-cards-wrapper"
      >
        <OurPortfolioCarousel selectedCategory={selectedCategory} />
      </Container>
    </div>
  )
}

export default OurPortfolioContent
