import React from "react"
import { Col, Container, Row, Button } from "react-bootstrap"
import Image from "@components/common/image"
import scrollTo from "gatsby-plugin-smoothscroll"

const OurPortfolioHero = () => {
  return (
    <Container className="our-portfolio-hero">
      <Row className="justify-content-around">
        <Col xs={12} md={8} lg={5} className="text-center text-lg-left">
          <div
            data-sal="slide-right"
            data-sal-delay="300"
            data-sal-easing="ease"
            data-sal-duration="1000"
            className="content-wrapper"
          >
            <h4>
              We build the runway for <span>exceptional talents</span> with
              exceptional ideas
            </h4>
            <p>
              We go all out looking for prudent, forward looking ventures with
              the right mix of expertise and innovation.
            </p>
            <Button
              onClick={() => scrollTo("#our-portfolio")}
              className="btn-primary"
            >
              <span>Our Portfolio</span>
              <Image
                style={{ maxWidth: "8px" }}
                className="icon"
                src="down-arrow-icon.png"
              />
            </Button>
          </div>
        </Col>

        <Col xs={12} className="d-lg-none">
          <Row className="images-container flex-nowrap overflow-auto">
            <Col xs={4} className="image-wrapper">
              <div
                data-sal="fade"
                data-sal-delay="300"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                <Image
                  className="img-fluid"
                  src="Maxwholesale.png"
                  title="MaxWholesale"
                  alt="MaxWholesale"
                />
              </div>
            </Col>
            <Col xs={4} className="image-wrapper">
              <div
                data-sal="fade"
                data-sal-delay="300"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                <Image
                  className="img-fluid"
                  src="bsquared.png"
                  title="BSquared"
                  alt="BSquared"
                />
              </div>
            </Col>
            <Col xs={4} className="image-wrapper">
              <div
                data-sal="fade"
                data-sal-delay="300"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                <Image
                  className="img-fluid"
                  src="bongo.png"
                  title="Bongo"
                  alt="Bongo"
                />
              </div>
            </Col>

            <Col xs={4} className="image-wrapper">
              <div
                data-sal="fade"
                data-sal-delay="300"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                <Image
                  className="img-fluid"
                  src="chaldal.png"
                  title="Chaldal"
                  alt="Chaldal"
                />
              </div>
            </Col>
            <Col xs={4} className="image-wrapper">
              <div
                data-sal="fade"
                data-sal-delay="300"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                <Image
                  className="img-fluid"
                  src="loosely_coupoled.png"
                  title="Loosely Coupoled"
                  alt="Loosely Coupoled"
                />
              </div>
            </Col>
            <Col xs={4} className="image-wrapper">
              <div
                data-sal="fade"
                data-sal-delay="300"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                <Image
                  className="img-fluid"
                  src="grubtech.png"
                  title="Grubtech"
                  alt="Grubtech"
                />
              </div>
            </Col>
            <Col xs={4} className="image-wrapper">
              <div
                data-sal="fade"
                data-sal-delay="300"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                <Image
                  className="img-fluid"
                  src="razor_capital.png"
                  title="Razor Capital"
                  alt="Razor Capital"
                />
              </div>
            </Col>

            <Col xs={4} className="image-wrapper">
              <div
                data-sal="fade"
                data-sal-delay="300"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                <Image
                  className="img-fluid"
                  src="dai.png"
                  title="DAI"
                  alt="DAI"
                />
              </div>
            </Col>
            <Col xs={4} className="image-wrapper">
              <div
                data-sal="fade"
                data-sal-delay="300"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                <Image
                  className="img-fluid"
                  src="global_gate.png"
                  title="Global Gate"
                  alt="Global Gate"
                />
              </div>
            </Col>
            <Col xs={4} className="image-wrapper">
              <div
                data-sal="fade"
                data-sal-delay="300"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                <Image
                  className="img-fluid"
                  src="the_captains_club.png"
                  title="The Captains Club"
                  alt="The Captains Club"
                />
              </div>
            </Col>
            <Col xs={4} className="image-wrapper">
              <div
                data-sal="fade"
                data-sal-delay="300"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                <Image
                  className="img-fluid"
                  src="awj.png"
                  title="AWJ"
                  alt="AWJ"
                />
              </div>
            </Col>
          </Row>
        </Col>

        <Col
          data-sal="slide-left"
          data-sal-delay="300"
          data-sal-easing="ease"
          data-sal-duration="1000"
          lg={6}
          className="d-none d-lg-block"
        >
          <Row className="justify-content-center">
            <Col md={3} className="image-wrapper">
              <div>
                <Image
                  className="img-fluid"
                  src="Maxwholesale.png"
                  title="MaxWholesale"
                  alt="MaxWholesale"
                />
              </div>
            </Col>
            <Col md={3} className="image-wrapper">
              <div>
                <Image
                  className="img-fluid"
                  src="bsquared.png"
                  title="BSquared"
                  alt="BSquared"
                />
              </div>
            </Col>
            <Col md={3} className="image-wrapper">
              <div>
                <Image
                  className="img-fluid"
                  src="bongo.png"
                  title="Bongo"
                  alt="Bongo"
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={3} className="image-wrapper">
              <div>
                <Image
                  className="img-fluid"
                  src="chaldal.png"
                  title="Chaldal"
                  alt="Chaldal"
                />
              </div>
            </Col>
            <Col md={3} className="image-wrapper">
              <div>
                <Image
                  className="img-fluid"
                  src="loosely_coupoled.png"
                  title="Loosely Coupoled"
                  alt="Loosely Coupoled"
                />
              </div>
            </Col>
            <Col md={3} className="image-wrapper">
              <div>
                <Image
                  className="img-fluid"
                  src="grubtech.png"
                  title="Grubtech"
                  alt="Grubtech"
                />
              </div>
            </Col>
            <Col md={3} className="image-wrapper">
              <div>
                <Image
                  className="img-fluid"
                  src="razor_capital.png"
                  title="Razor Capital"
                  alt="Razor Capital"
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={3} className="image-wrapper">
              <div>
                <Image
                  className="img-fluid"
                  src="dai.png"
                  title="DAI"
                  alt="DAI"
                />
              </div>
            </Col>
            <Col md={3} className="image-wrapper">
              <div>
                <Image
                  className="img-fluid"
                  src="global_gate.png"
                  title="Global Gate"
                  alt="Global Gate"
                />
              </div>
            </Col>
            <Col md={3} className="image-wrapper">
              <div>
                <Image
                  className="img-fluid"
                  src="the_captains_club.png"
                  title="The Captains Club"
                  alt="The Captains Club"
                />
              </div>
            </Col>
            <Col md={3} className="image-wrapper">
              <div>
                <Image
                  className="img-fluid"
                  src="awj.png"
                  title="AWJ"
                  alt="AWJ"
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default OurPortfolioHero
